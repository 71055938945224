<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="main-wrap">
			<div class="benifitHeader">
				<div class="title">{{ tenantName }}收益(元)</div>
				<div class="benifit">{{ getValue('incomeTotal') }}</div>
				<div class="title">
					昨天收益
					<span style="font-weight: bold">{{ getValue('yesDayIncome') }}</span>
					元
				</div>
			</div>

			<div class="contentView">
				<div class="contentLine">
					<div class="title">当月收支(元)</div>
					<div class="content">{{ getValue('monthTotal') }}</div>
				</div>

				<div class="contentLine">
					<div class="title">收入</div>
					<div class="content">{{ getValue('monthIncome') }}</div>
				</div>

				<div class="contentLine">
					<div class="title">支出</div>
					<div class="content">{{ getValue('monthCost') }}</div>
				</div>
			</div>

			<div class="contentView">
				<div class="contentLine">
					<div class="title">本周收支(元)</div>
					<div class="content">{{ getValue('weekTotal') }}</div>
				</div>

				<div class="contentLine">
					<div class="title">收入</div>
					<div class="content">{{ getValue('weekIncome') }}</div>
				</div>

				<div class="contentLine">
					<div class="title">支出</div>
					<div class="content">{{ getValue('weekCost') }}</div>
				</div>
			</div>

			<div class="contentView">
				<div class="contentLine">
					<div class="title">本年收支(元)</div>
					<div class="content">{{ getValue('yearTotal') }}</div>
				</div>

				<div class="contentLine">
					<div class="title">收入</div>
					<div class="content">{{ getValue('yearIncome') }}</div>
				</div>

				<div class="contentLine">
					<div class="title">支出</div>
					<div class="content">{{ getValue('yearCost') }}</div>
				</div>
			</div>

			<work-benifit-chart @changeTime="getData" ref="workChart" :chart-list="chartList"></work-benifit-chart>
		</div>
	</van-pull-refresh>
</template>

<script>
import WorkBenifitChart from './workBenifitChart';
import { getDate } from '@utils/utils.js';
export default {
	name: 'workBenifit',
	components: { WorkBenifitChart },
	props: {
		query: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
			isRefresh: false,

			userInfo: {},
			inComeInfo: {},
			chartList: []
		};
	},
	computed: {
		tenantId() {
			if (this.$valueIsExist(this.userInfo, 'tenantId') == false) {
				return '';
			}
			return this.userInfo['tenantId'];
		},
		tenantName() {
			if (this.$valueIsExist(this.userInfo, 'tenantName') == false) {
				return '';
			}
			return this.userInfo['tenantName'];
		}
	},
	mounted() {
		// this.$bus.on('updateList', this.getData);
		// this.addLocalStorageEventByKey('warkSuccess', this.getData);
		// this.$nextTick(() => {
		// 	this.getData();
		// });
		this.userInfo = this.$store.getters.userInfo;
		this.getData();
	},
	beforeDestroy() {
		// this.$bus.off('updateList', this.getData);
		// this.removeLocalStorageEvent(this.getData);
	},
	methods: {
		onRefresh() {
			this.isRefresh = true;
			this.getData(() => {
				setTimeout(() => {
					this.isRefresh = false;
				}, 300);
			});
		},
		getValue: function(key) {
			if (this.$valueIsExist(this.inComeInfo, key) == false) {
				return '+0';
			}
			let value = this.inComeInfo[key];
			let money = Math.abs(value);
			let moneyStr = money;
			if (money < 10000) {
			} else if (money >= 10000 && money < 100000000) {
				moneyStr = (money / 10000.0).toFixed(1) + '万';
			} else {
				moneyStr = (money / 100000000.0).toFixed(1) + '亿';
			}

			return `${value >= 0 ? '+' : '-'}${moneyStr}`;
		},
		getData(callback) {
			let deviceShareYearCount = () => {
				return new Promise(resolve => {
					let tenantIds = '';
					if (this.$valueIsExist(this.query, 'tenantIds')) {
						tenantIds = this.query['tenantIds'];
					}

					let startTime = '';
					if (this.$valueIsExist(this.query, 'startTime')) {
						startTime = this.query['startTime'];
					}
					let year = '';

					if (startTime == '') {
						let date = new Date();
						year = date.getFullYear();
					} else {
						let date = new Date(startTime);
						year = date.getFullYear();
					}

					let query = {
						isTenantIdChild: 1,
						year: year,
						tenantIds: tenantIds
					};

					this.$api.deviceWorkManager
						.deviceShareYearCount(query)
						.then(res => {
							let info = {};
							if (this.$valueIsExist(res, 'data')) {
								info = res.data;
							}
							resolve({ result: true, info: info });
						})
						.catch(e => {
							resolve({ result: true, info: {} });
						});
				});
			};

			let deviceShareChart = () => {
				let tenantIds = '';
				if (this.$valueIsExist(this.query, 'tenantIds')) {
					tenantIds = this.query['tenantIds'];
				}

				let query = {
					isTenantIdChild: 1,
					tenantIds: tenantIds,
					startTime: getDate(new Date().getTime() - 6 * 8.64e7) + ' 00:00:00',
					endTime: getDate() + ' 23:59:59'
				};

				return new Promise(resolve => {
					this.$api.deviceWorkManager
						.deviceShareChart(query)
						.then(res => {
							let list = [];
							if (this.$valueIsExist(res, 'data')) {
								list = res.data;
							}

							resolve({ result: true, list: list });
						})
						.catch(e => {
							resolve({ result: true, list: [] });
						});
				});
			};

			Promise.all([deviceShareYearCount(), deviceShareChart()])
				.then(res => {
					this.inComeInfo = res[0].info;
					this.chartList = res[1].list;
					if (callback) callback();
				})
				.catch(() => {
					if (callback) callback();
				});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.main-wrap {
	.benifitHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 18px 0;

		.title {
			font-size: 12px;
			color: #fff;
		}

		.benifit {
			font-size: 28px;
			font-weight: 700;
			color: #fff;
			line-height: 30px;
			margin: 6px 0;
		}
	}

	.contentView {
		background: #fff;
		width: calc(100% - 20px);
		margin-left: 10px;
		border-radius: 8px;
		margin-bottom: 10px;
		padding: 12px;

		.contentLine {
			width: 100%;
			line-height: 25px;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				color: #666;
			}

			.content {
				color: #333;
				font-weight: 700;
			}
		}
	}
}
</style>
