<template>
	<div class="workBenifitChart">
		<div class="title">收益概览</div>
		<div class="workBenifitChartView" id="workBenifitChartView"></div>
	</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
	name: 'workBenifitChart',
	props: {
		chartList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	watch: {
		chartList: {
			handler(value) {
				this.$nextTick(() => {
					this.setOptions();
				});
			},
			immediate: true,
			deep: true
		}
	},
	data() {
		return {
			myChart: null
		};
	},
	mounted() {},
	methods: {
		setOptions: function() {
			if (this.myChart == null) {
				this.myChart = echarts.init(document.getElementById('workBenifitChartView'));
			}
			let xList = [];
			let yList = [];
			let incomeList = [];
			for (let i = 0; i < this.chartList.length; i++) {
				let data = this.chartList[i];
				let time = new Date(data['day']);
				xList.push([time.getFullYear(), time.getMonth() + 1, time.getDate()].join('/'));
				yList.push(data['amount']);
				incomeList.push(data['income']);
			}

			let option = {
				tooltip: {
					trigger: 'axis',
					formatter: function(params) {
						let str = '';
						params.forEach((item, idx) => {
							let msg = `${item.name} <span style="color:${item.color}">${item.seriesName}</span> ${
								typeof item.value == 'undefined' || item.value == null ? 0 : item.value
							}元`;
							str += msg + '<br>';
						});
						return str;
					}
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: xList
				},
				yAxis: {
					type: 'value',
					boundaryGap: [0, '100%']
				},
				// dataZoom: [
				// 	{
				// 		type: 'inside',
				// 		start: 0,
				// 		end: 100
				// 	},
				// 	{
				// 		start: 0,
				// 		end: 100
				// 	}
				// ],
				grid: [
					{
						right: '5%',
						left: '5%',
						bottom: '5%',
						top: '5%',
						containLabel: true
					}
				],
				series: [
					{
						name: '支出',
						type: 'line',
						symbol: 'none',
						sampling: 'lttb',
						itemStyle: {
							color: '#0279E1',
              normal: {
                lineStyle: {
                  width: 1,
                }
              }
						},
						areaStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{
									offset: 0,
									color: '#0279E1'
								},
								{
									offset: 1,
									color: '#E3F0FA'
								}
							])
						},
						data: yList
					},
					{
						name: '收入',
						type: 'line',
						symbol: 'none',
						sampling: 'lttb',
						itemStyle: {
							color: '#02e14c',
              normal: {
                lineStyle: {
                  width: 1,
                }
              }
						},
						areaStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{
									offset: 0,
									color: '#02e14c'
								},
								{
									offset: 1,
									color: '#E3F0FA'
								}
							])
						},
						data: incomeList
					}
				]
			};
			this.myChart.setOption(option);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.workBenifitChart {
	padding: 12px;
	background: #fff;
	width: calc(100% - 20px);
	margin-left: 10px;
	border-radius: 8px;
	.title {
		padding-left: 8px;
		font-size: 14px;
		font-weight: 700;
		color: #333;
		margin-bottom: 10px;
	}
	.workBenifitChartView {
		height: 120px;
		width: 100%;
	}
}
</style>
